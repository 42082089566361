import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AppUpdateService } from './shared/services/app-update.service';
import { GlobalService } from './shared/services/global.service';
import { LangService } from './shared/services/lang.service';
import { EnvService } from './shared/services/env.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  subscriptionNavStart: Subscription;
  profileForm: FormGroup;

  constructor(
    private router: Router,
    ngbModalConf: NgbModalConfig,
    private global: GlobalService,
    appUpdate: AppUpdateService,
    public lang: LangService, // just inject this to load the translation file from the server (from the constructor)
    public envService: EnvService,
  ) {
    // config default options for mngb modal dialog component
    ngbModalConf.keyboard = false;
    ngbModalConf.backdrop = 'static';

  }

  ngOnInit() {
    // on route change, scroll window to top
    this.subscription = this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe((event) => {
        window.scrollTo(0, 0);
      });

    // get url early in to global service
    this.subscriptionNavStart = this.router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe((event: NavigationStart) => {
        this.global.setUrlDetails(event.url);
      });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.subscriptionNavStart) {
      this.subscriptionNavStart.unsubscribe();
    }
  }
}
